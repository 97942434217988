@use '@packages/theme/styles/base' as *;

.root {
	display: grid;
}

.hero {
	display: grid;
	height: 100vh;
	height: 100svh;
}

.backgroundImageContainer {
	position: absolute;
	z-index: -1;
	inset: 0;

	img {
		inset: 0;
	}
}

.heroImage {
	position: absolute;
	width: 100%;
	height: 100%;
}

.heroImagePortrait {
	display: none;
	@media (aspect-ratio <= 10 / 10) {
		display: block;
	}
}

.heroImageLandscape {
	display: block;

	@media (aspect-ratio <= 10 / 10) {
		display: none;
	}
}
