@use '@packages/theme/styles/base' as *;

.root {
	color: var(--color-brand);

	svg {
		width: auto;
		height: 100%;
	}
}
