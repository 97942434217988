@use '@packages/theme/styles/base' as *;

.root {
	position: fixed;
	z-index: 10;
	top: 0;
	right: 0;
	left: 0;
	backdrop-filter: blur(16px);
	background-color: transparent;
	transition: background-color 0.6s;
}

.withBackground {
	background-color: rgb(6 17 53 / 0.8);
	@include mq($from: sm) {
		background-color: rgba(4 12 37 / 0.8);
	}
}

.content {
	position: relative;
	display: grid;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	grid-template-columns: 1fr auto;
	place-items: center center;

	@include mq($from: sm) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}
